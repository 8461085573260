

<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import { toPayment } from '@/helpers/donate';
import validate from "@/helpers/validate";

export default {
  name: "balance",
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Баланс",
      items: [
        {
          text: "Аккаунт",
          href: "/",
        },
        {
          text: "Мой профиль",
          href: "/",
        },
        {
          text: "Баланс",
          active: true,
        },
      ],
      errors: {},
      payment: {
        type: null,
        count: null,
        email: null,
      }
    }
  },
  mounted() {
    this.payment.email = this.user.email;
  },
  methods: {
    async toPayment() {
      this.errors = {};
      this.errors.email = validate.email(this.payment.email);
      this.errors.count = validate.count(this.payment.count, 10);
      if (!this.payment.type) {
        this.errors.type = 'Выберите тип';
      }

      if (!this.errors.count && !this.errors.email && !this.errors.type) {
        await toPayment(this.payment.type, this.payment.count, this.payment.email);
      }
    }
  },
  computed: {
    user: function() {
      return this.$store.getters['auth/user'];
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="card col-lg-8 mt-4 p-3 mx-auto" data-aos="fade-up">
      <div class="card-header my-0">
        <h5>Пополнить баланс</h5>
        <p class="text-muted my-0">
          Вы можете пополнить баланс удобным для Вас способом
        </p>
      </div>
      <div class="card-body py-4">
        <div class="row g-4 py-2">
          <div class="col-lg-4 col-sm-6">
            <div class="form-check card-radio">
              <input
                  id="paymentMethod01"
                  name="paymentMethod"
                  type="radio"
                  @click="payment.type = 'card'"
                  class="form-check-input"
              />
              <label class="form-check-label" for="paymentMethod01">
                          <span class="fs-16 text-muted me-2">
                            <i class="ri-bank-card-fill align-bottom"></i>
                          </span>
                <span class="fs-14 text-wrap">
                  Банковские карты
                  <p class="fs-10 p-0 m-0">Visa, Mastercard, Maestro, Мир, JCB, UnionPay</p>
                </span>
              </label>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6">
            <div class="form-check card-radio">
              <input
                  id="paymentMethod03"
                  name="paymentMethod"
                  type="radio"
                  @click="payment.type = 'umoney'"
                  class="form-check-input"
              />
              <label class="form-check-label" for="paymentMethod03">
                          <span class="fs-16 text-muted me-2">
                            <i class="ri-wallet-3-fill align-bottom"></i>
                          </span>
                <span class="fs-14 text-wrap">
                  ЮMoney
                  <p class="fs-10 m-0">Кошельки ЮMoney, привязанные карты и ЮCard</p>
                </span>
              </label>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6">
            <div class="form-check card-radio">
              <input
                  id="paymentMethod04"
                  name="paymentMethod"
                  type="radio"
                  @click="payment.type = 'qiwi'"
                  class="form-check-input"
              />
              <label class="form-check-label" for="paymentMethod04">
                          <span class="fs-16 text-muted me-2">
                            <i class="ri-wallet-2-fill align-bottom"></i>
                          </span>
                <span class="fs-14 text-wrap">
                  QIWI Кошелёк
                  <p class="fs-10 m-0">Электронные кошельки QIWI</p>
                </span>
              </label>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6">
            <div class="form-check card-radio">
              <input
                  id="paymentMethod06"
                  name="paymentMethod"
                  type="radio"
                  @click="payment.type = 'perfect'"
                  class="form-check-input"
              />
              <label class="form-check-label" for="paymentMethod06">
                          <span class="fs-16 text-muted me-2">
                            <i class="ri-coin-fill align-bottom"></i>
                          </span>
                <span class="fs-14 text-wrap">
                  Perfect Money
                  <p class="fs-10 m-0">Электронные кошельки Perfect Money</p>
                </span>
              </label>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6">
            <div class="form-check card-radio">
              <input
                  id="paymentMethod07"
                  name="paymentMethod"
                  type="radio"
                  @click="payment.type = 'sbp'"
                  class="form-check-input"
              />
              <label class="form-check-label" for="paymentMethod07">
                          <span class="fs-16 text-muted me-2">
                            <i class="ri-qr-code-fill align-bottom"></i>
                          </span>
                <span class="fs-14 text-wrap">
                  СБП
                  <p class="fs-10 m-0">Система быстрых платежей</p>
                </span>
              </label>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6">
            <div class="form-check card-radio">
              <input
                  id="paymentMethod08"
                  name="paymentMethod"
                  type="radio"
                  @click="payment.type = 'crypto'"
                  class="form-check-input"
              />
              <label class="form-check-label" for="paymentMethod08">
                          <span class="fs-16 text-muted me-2">
                            <i class="ri-bit-coin-fill align-bottom"></i>
                          </span>
                <span class="fs-14 text-wrap">
                  Другое
                  <p class="fs-10 m-0">Оплата через другие сервисы</p>
                </span>
              </label>
            </div>
          </div>
        </div>
        <span class="mt-2 text-danger" v-if="errors.type">{{ errors.type }}</span>

        <div class="row mb-5 mt-3 py-2">
          <div class="col-lg-6">
            <label for="money" class="form-label">Количество</label>
            <div class="input-group mb-3">
              <span class="input-group-text">₽</span>
              <input type="number" v-model="payment.count"
                     class="form-control"
                     placeholder="Введите количество волюты для покупки"
                     :class="{ 'is-invalid': errors.count }"
              >
              <div
                  v-for="(item, index) in errors.count"
                  :key="index"
                  class="invalid-feedback"
              >
                <span v-if="item"> {{ item }} </span>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <label for="money" class="form-label">E-mail</label>
            <div class="input-group mb-3">
              <span class="input-group-text">@</span>
              <input type="text"
                     v-model="payment.email"
                     class="form-control"
                     placeholder="Введите почту для уведомления"
                     :class="{ 'is-invalid': errors.email }"
              >
              <div
                  v-for="(item, index) in errors.email"
                  :key="index"
                  class="invalid-feedback"
              >
                <span v-if="item"> {{ item }} </span>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-start gap-3 mt-4">
          <router-link
              type="button"
              class="btn btn-light btn-label previestab"
              data-previous="pills-bill-address-tab"
              to="/donate"
          >
            <i class="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
            Магазин
          </router-link>
          <button
              type="button"
              class="btn btn-primary btn-label right ms-auto"
              @click="toPayment()"
          >
            <i class="ri-copper-coin-line label-icon align-middle fs-16 ms-2"></i>
            Пополнить
          </button>
        </div>
      </div>
    </div>
    <!-- end tab pane -->
  </Layout>
</template>

<style scoped>

</style>