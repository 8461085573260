import axios from 'axios';
import { printError } from "@/helpers/request";
import { useToast } from "vue-toastification";
const toast = useToast();

export async function buyProduct(key, count) {
    return await axios.post('shop/buy', {
        key, count
    }).then(() => {
        toast.success("Успешно! Товар выдан Вам на сервере!");
    }).catch(printError);
}

export function toPayment(type, count, email) {
    return axios.get(`/payment/${type}`, {
        params: {
            count,
            email
        }
    }).then(({ data }) => {
        console.log(data);
        window.location = data;
    }).catch(printError);
}